<template>
    <div class="login">
        <mdos-login @passwordChange="pwdVisible = true"></mdos-login>
        <el-dialog :visible.sync="pwdVisible" fullscreen :show-close="false">
            <pwd-change></pwd-change>
        </el-dialog>
    </div>
</template>

<script>
import pwdChange from '../pwdChange';
import MdosLogin from '@/components/login/login';

export default {
    name: 'Login',
    data: () => {
        return {
            pwdVisible: false
        };
    },
    components: {
        pwdChange,
        MdosLogin
    }
};
</script>
<style lang="less" scoped>
.login {
    /deep/ .el-dialog__header {
        padding: 0;
    }
    /deep/ .el-dialog__body {
        padding: 0;
    }
}
</style>
