<template>
    <div class="login"></div>
</template>

<script>
import { mapActions } from 'vuex';
import { getQueryString } from '@/utils';
export default {
    name: 'ssologin',
    data: () => {
        return {
            token: getQueryString('token'),
            userId: getQueryString('userId'),
            redirect: getQueryString('redirect')
        };
    },
    methods: {
        ...mapActions(['getUsersInfo', 'setState']),
        checkLogin() {
            if (this.userId && this.token) {
                this.setState({
                    token: this.token,
                    userId: this.userId
                });
                this.getUsersInfo().then(() => {
                    window.location.href = this.redirect || '/';
                });
            }
        }
    },
    mounted() {
        this.checkLogin();
    }
};
</script>
<style lang="less" scoped>
.login {
    /deep/ .el-dialog__header {
        padding: 0;
    }
    /deep/ .el-dialog__body {
        padding: 0;
    }
}
</style>
