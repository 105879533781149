<template>
    <div class="login" ref="login">
        <div class="login-bg">
            <div class="login-content">
                <div class="login-logo">
                    <img src="./img/logo_v2.png" alt />
                </div>
                <div class="login-center">
                    <div class="login-center-title">登录</div>
                    <el-form
                        ref="form"
                        label-position="top"
                        class="login-message"
                        :model="form"
                        label-width="40px"
                    >
                        <el-form-item>
                            <el-input
                                v-model.trim="form.userName"
                                prefix-icon="el-icon-user"
                                @keyup.enter.native="login"
                                placeholder="请输入手机号或工号"
                            />
                        </el-form-item>
                        <el-form-item>
                            <el-input
                                v-model.trim="form.password"
                                prefix-icon="el-icon-lock"
                                :type="passw"
                                @input="checkInput"
                                @keyup.enter.native="login"
                                placeholder="6-20位数字、字母组合"
                            >
                                <i
                                    slot="suffix"
                                    class="iconfont"
                                    @click="showPass"
                                    v-html="icon"
                                ></i>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <div class="login-message">
                        <el-button
                            class="login-submit"
                            type="primary"
                            :loading="loading"
                            @click="login"
                        >
                            {{ loadingText }}
                        </el-button>
                    </div>
                    <p class="phone-login">
                        <span @click="onPhone">手机验证码登录</span>
                    </p>
                </div>
            </div>
            <particles></particles>
        </div>
    </div>
</template>

<script>
import { encode } from 'base-64';
import { URLencode, loginEvent } from '@/utils';
import Particles from './particles';

export default {
    name: 'MdosLogin',
    data: () => {
        return {
            form: {
                verifyCode: '',
                userName: '',
                uniqueId: '',
                password: ''
            },

            icon: '&#xe610;',
            //用于改变Input类型
            passw: 'password',

            loading: false
        };
    },
    computed: {
        loadingText() {
            return this.loading ? '登录中...' : '登录';
        }
    },

    components: {
        Particles
    },
    props: {
        // loading: {
        //     type: Boolean,
        //     defualt: false
        // }
    },
    methods: {
        // 登陆
        async login() {
            const params = { ...this.form };

            if (!this.form.userName) {
                this.$message.error('账号不能为空');
                return;
            }
            if (!this.form.password) {
                this.$message.error('密码不能为空');
                return;
            }

            if (this.loading) {
                //正在登录中不允许再次请求
                return;
            }

            params.password = encode(URLencode(this.form.password));
            params.loginType = 0;
            params.loginSource = 2;
            params.loginEquipment = navigator.userAgent; // 用户登录的浏览器基础信息
            loginEvent(this, params);
        },

        //密码的隐藏和显示
        showPass() {
            //点击图标是密码隐藏或显示
            if (this.passw == 'text') {
                this.passw = 'password';
                //更换图标
                this.icon = '&#xe610;';
            } else {
                this.passw = 'text';
                this.icon = '&#xe605;';
            }
        },

        //检查，禁止输入中文
        checkInput(val) {
            val = val.replace(/[ ]/g, '');
            this.form.password = val.replace(/[\u4E00-\u9FA5]/g, '');
        },
        onPhone() {
            this.$router.push({ path: '/phoneLogin' });
        }
    },
    mounted() {
        this.$refs['login'].style.height = `${document.body.offsetHeight}px`;
    }
};
</script>
<style lang="less" scoped>
#particles {
    top: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.login {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(180deg, #f9fbff 0%, #f2f7ff 100%);
}
.login-bg {
    height: 100%;
    width: 100%;
    background: url('./img/bg_login_botleft.png') no-repeat bottom left,
        url('./img/bg_login_botright.png') no-repeat bottom right;
    background-size: 40%;
}
.login-logo {
    position: absolute;
    top: -122px;
    left: 46px;
    img {
        width: 360px;
    }
}
.logo {
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #999;
    position: relative;
    left: 80px;
    top: 16px;
    img {
        width: 28px;
        height: 28px;
    }
}
.login-content {
    width: 460px;
    height: 420px;
    background: #fff;
    margin: 0 auto; /*水平居中*/
    position: absolute;
    top: 50%; /*偏移*/
    left: 50%;
    z-index: 1999;
    margin-top: -200px;
    margin-left: -230px;
    // transform: translate(calc(50% - 460px), calc(50% - 400px));
    box-shadow: 0px 6px 24px 4px rgba(56, 162, 246, 0.2);
    box-sizing: border-box;
    padding: 50px 42px;
}
.login-center {
    background: #fff;

    /deep/ .el-input {
        height: 32px;
        border-radius: 1px;
        .el-input__inner {
            border: 1px solid rgba(224, 224, 224, 1);
            color: #666660 !important;
            font-size: 14px;
            height: 46px;
            background: #fff;
        }
    }
    /deep/ .el-form-item {
        margin-bottom: 36px;
    }
    /deep/ .el-input--prefix .el-input__inner {
        padding-left: 42px;
    }
    /deep/ .el-input__prefix {
        top: 3px;
        left: 12px;
        i {
            font-size: 16px;
            color: #1089e9;
        }
    }
}
.login-center-title {
    text-align: center;
    letter-spacing: 4px;
    font-size: 26px;
    margin-bottom: 40px;
    line-height: 36px;
    color: #333;
}
.login-message {
    margin: 0 auto;
}
.iconfont {
    margin-top: 4px;
    margin-right: 10px;
    display: block;
    cursor: pointer;
}
.login-submit {
    width: 100%;
    margin-top: 10px;
    border-radius: 3px;
    height: 46px;
    &:hover {
        opacity: 0.8;
    }
    /deep/ span {
        color: rgba(255, 255, 255, 1);

        font-size: 14px;
        letter-spacing: 6px;
    }
}
.phone-login {
    font-size: 14px;
    color: #0d93f1;
    line-height: 14px;
    text-align: right;
    margin-top: 40px;
    cursor: pointer;
}
@media screen and (max-width: 1280px) {
    .login-content {
        width: 420px;
        height: 380px;
        margin-top: -160px;
        margin-left: -210px;
        padding: 35px 42px;
        // transform: translate(calc(50% - 420px), calc(50% - 360px));
    }
    .phone-login {
        margin-top: 23px;
    }
    .login-logo {
        top: -96px;
        left: 64px;
        img {
            width: 300px;
        }
    }
    .login-center-title {
        letter-spacing: 4px;
        font-size: 24px;
        line-height: 32px;
    }
}
</style>
